.block1-cover-outter-div{
    background-color: white;
    padding-top: 10%;
    padding-bottom: 5%;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    height: calc(100vh - 80px);
}

.block1-cover-inner-div{
    display: flex;
    justify-content: space-between;
    margin-right: 4%;
}

.block1-left-div{
    width: 50%;
    height: 100%;
    margin-top: 3%;
}

.block1-right-div{
    width: 50%;
    height: 100%;
}

.block1-h1-left-div{
    font-weight: 600;
    margin-bottom: 25px;
    font-size: 40px;
}

.block1-p-left-div{
    font-weight: 400;
    margin-right: 20px;
    font-size: 16px;
}

.block1-curved-div{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom:10px;
    background-color: #DAFCFA;
    border-radius: 75px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    margin-right: 15px;
    font-weight: 500;
    font-size: 15px;
    margin-left: 0;
}

.block1-line-breaker{
    margin-bottom: 20px;
}

.block1-image{
    width: 100%;
    height: auto;
}

.block1-button-image{
    margin-left: 0px;
    width: 50%;
    height: 50%;
}

.block1-button{
    margin-left: 0px;
    margin-top: 50px;
    border: none;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #007BFF;
    color: white;
    font-weight: normal;
    border-radius: 75px;
    font-size: 17px;
}

.block1-inline{
    display: flex;
}