.block3-outter-div{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 2%;
    background-color: white;
    border-radius: 30px;
    padding-right: 2%;
    padding-left: 2%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.block3-text{
    font-weight: 900;
    text-align: center;
    margin-bottom: 5%;
}

.block3-demo-image-div{
    width: 49%;
    border-radius: 15px;
}

.block3-image{
    width: 100%;
    height: 100%;
    margin-left: 0;
}

.block3-demo-sample-outter-div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
}

.block3-cv-div-cover{
    display: flex;
}

.block3-cv-sample{
    width: 98%;
    border-radius: 15px;
    margin-left: 0;
    margin-right: 0;
}