.react-pdf__Page__canvas {
    margin: 0;
    width: 100% !important;
    height: auto !important;
    border-radius: 0px;
    font-size: 0;
}

.outter-div-cv{
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1%;
}

.inner-div-display-cv{
    width:49%;
    background-color: #F5F6F9;
    padding: 1%;
    border-radius: 15px;
    height: 950px;
    overflow: auto;
}

.inner-div-display-result{
    width:49%;
    background-color: #F5F6F9;
    padding-right: 1%;
    padding-left: 1%;
    padding-top: 2%;
    padding-bottom: 15%;
    border-radius: 30px;
    height: 950px;
    overflow: auto;
}

.div-display-result{
    width: 100%;
    color: black;
    background-color: white;
    border-radius: 5px;
    padding-bottom: 5%;
    border: none;
}

.display-same-line{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
}

.pdf-cv-demo-butt{
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 75px;
    width: fit-content;
    padding: 5px 15px;
    cursor: pointer;
}:disabled{
    background-color: lightskyblue;
}

.btn-primary:focus{
    background-color: lightblue !important;
    border: none !important;
    color: black !important;
    border: none !important;
    box-shadow: 0 0 0 0rem white !important;
    border-radius: 10px 10px 5px 5px;
}

.btn{
    text-align: left !important;
    background-color: white !important;
    color: black !important;
    border: none !important;
    padding: 2% 4% !important;
}

.collapse-div{
    width: 100%;
    color: black;
    background-color: white;
    border-radius: 5px;
    padding: 2% 4%;
    border: none;
    margin-top: 1%;
    margin-bottom: 2%;
}

.border-div{
    margin-bottom: 5%;
}

.time-div{
    padding: 1% 2%;
    border-radius: 75px;
    background-color: #F5F6F9;
    font-weight: 900;
    font-size: 15px;
    width: fit-content;
    margin-bottom: 1%;
}

.small-title{
    font-size: 17px;
    font-weight: bolder;
    margin-bottom: 1%;
}

.block3-cv-image{
    border-radius: 15px;
}

.margin_bottom_top{
    margin-top: 5px;
    margin-bottom: 25px;
}