.body{
    background-color: #E5E5E5;
    font-family: 'Montserrat', sans-serif;
}

.demo-text-center{
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 40px;
}

.button-hover:hover{
    background-color: rgb(47, 189, 236);
    cursor: pointer;
}