img {
    margin-left: 9%;
}

.navbar-light .navbar-nav .nav-link {
    color: black !important;
}

.navbar {
    height: 80px;
}

.margin-menu-nav-bar {
    margin-right: 5%;
}

.link-padding-right {
    font-weight: 600;
    margin-right: 50px;
}