.footer-outter-div{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 3%;
}

.footer-logo{
    margin-left: 0;
}

.footer-inner-div{
    display: flex;
}

.footer-text-div{
    margin-top: 3%;
    width: 50%;
}

.footer-icon-left{
    margin-left: 0;
    width: 35px;
    height: 35px;
}

.footer-icon-right{
    margin-left: 15%;
    width: 25px;
    height: 25px;
    border-radius: 2px;
}

.footer-text-left-div{
    padding-right: 15%;
    padding-left: 15px;
    font-size: 13px;
}

.break-line{
    margin-bottom: 15px;
}

.footer-text-right-div{
    padding-left: 15%;
    font-size: 13px;
    text-align: justify;
    margin-bottom: 35px;
}

.footer-left-p{
    margin-top: 6px;
    margin-bottom: 30px;
}

.footer-right-p{
    margin-top: 3px;
    margin-bottom: 15px;
    margin-left: 13px;
    font-size: 11px;
    font-weight: 900;
}

.footer-line{
    width: 80%;
    display: block;
    border-top: 1px solid #ffffff ;
    margin-bottom: 0px;
}

.footer-last-line{
    font-size: 12px;
    text-align: center;
    padding-top: 1%;
    padding-bottom: 1%;
    margin-bottom: 0;
}