.block2-outter-div{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 2%;
    background-color: white;
    border-radius: 30px;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.block2-button{
    border: none;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #007BFF;
    color: white;
    font-weight: normal;
    border-radius: 75px;
    width: fit-content;
    font-size: 15px;
}

.block2-upload-file{
    width: 100%;
    padding-left: 35px;
    padding-right: 10px;
    padding-bottom: 0px;
    border-radius: 75px;
    margin-bottom: 3%;
    border: 1px solid #007BFF;
    display: flex;
    justify-content: space-between;
}

.block2-icon-upload{
    height: 100%;
    font-size: 20px;
    opacity: 34%;
    font-weight: 300;
    padding-top: 15px;
    margin-right: 10px;
}

.block2-label{
    background-color: #35E1C0;
    color: white;
    padding: 10px 30px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 75px;
    margin-top: 1%;
    color: black;
}

.block2-label:hover{
    background-color: teal;
}

.block2-same-line{
    display: flex;
}

.block2-slash{
    font-size: 35px;
    padding-top: 0;
    padding-right: 20px;
    font-weight: lighter;
    opacity: 34%;
}

