.block4-outter-div{
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 2%;
    background-color: white;
    border-radius: 30px;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
    height: auto;
    align-items: center;
}

.block4-text-title{
    font-weight: 900;
    text-align: center;
    font-size: 35px;
}

.block4-image-div{
    text-align: center;
}

.block4-image{
    width: 50%;
}

.block4-curved-div{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #FF862C;
    border-radius: 75px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: white;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 20px;
    font-size: 20px;
}

.block4-text{
    margin-top: 5%;
    padding-left: 20%;
    padding-right:20%;
    font-size: 20px;
    text-align: center;
}

.block4-form-box{
    margin-top: 5%;  
    padding-left: 15%;
    padding-right: 15%;
    font-size: 15px;
}

.block4-form-input{
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 75px;
    border: solid 0.5px #E4E4E4;
    background-color: #F5F6F9;
    margin-bottom: 3%;
}

.block4-form-textarea{
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 25px;
    padding-bottom: 15px;
    border-radius: 30px;
    border: solid 0.5px #E4E4E4;
    background-color: #F5F6F9;
    margin-bottom: 10%;
}

.block4-form-button{
    border: none;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #007BFF;
    color: white;
    font-weight: lighter;
    border-radius: 75px;
    margin-bottom: 5%;
    font-size: large;
}

.block4-form{
    display: inline-block;
    text-align: center;
}